import React from 'react';
import { NumericFormat } from 'react-number-format';
import './styles.scss';

interface CNumberToMillionsProps {
  number: number;
}

const CNumberToMillions: React.FC<CNumberToMillionsProps> = ({ number }) => {

  const convertToMillions = (inputNumber: string) => {
    const number = parseFloat(inputNumber);
    if (!isNaN(number)) {
      const millions = number / 1000000;
      return parseFloat(millions.toFixed(0));
    }
  };

  return (
    <span>
      <NumericFormat suffix={'M'} value={convertToMillions(number.toString())} displayType={'text'} thousandSeparator={true} />
    </span>
  );
};

export default CNumberToMillions;
