import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks';

export default function ProtectedRoute({ component: Component, reverse, ...rest }) {
  const auth = useAuth();

  if (!auth.isLogin) {
    if (reverse === true) {
      return <Route {...rest} render={props => <Component {...props} />}/>;
    }
    return <Route {...rest} render={props => <Redirect to="/login" />}/>;
  }
  if (reverse === true) {
    return <Route {...rest} render={props => <Redirect to="/" />}/>;
  }
  return <Route {...rest} render={props => <Component {...props} />}/>;
}
