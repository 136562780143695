// DashboardFeature.tsx
import React, { useState } from 'react';
import Layout from '../../layouts/Main';
import Summary from './components/Summary';
import CustomerStats from './components/CustomerStats';
import AssetAllocation from './components/AssetAllocation';
import StockPorfolio from './components/StockPorfolio';
import Customer from './components/Customer';
import WatchList from './components/WatchList';
import CashFlow from './components/CashFlow';
import PortfolioDetails from './components/PortfolioDetails';
import Import from './components/Import';
import Draggable from 'react-draggable';
import { Col, Row, Drawer } from 'antd';
import { Box, Container, Button } from '@chakra-ui/react';
import { useCustomer, useWatchList, useCashFlow, usePortfolioDetails } from '../../hooks';

const DashboardFeature: React.FC = () => {

  const { customer } = useCustomer();
  const { watchList } = useWatchList();
  const { cashFlow } = useCashFlow();
  const { portfolioDetails } = usePortfolioDetails();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <Container maxW={'container.6xl'}>
        <Row>
          <Col span={24}>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <Summary />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <CustomerStats scrollX={''} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <AssetAllocation />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <StockPorfolio />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <PortfolioDetails data={portfolioDetails} />
            </Box>
          </Col>
        </Row>
        <Draggable>
          <Button
            className={'import'}
            onClick={showDrawer}
            as={'button'}
            position={'fixed'}
            bottom={'125px'}
            right={'0px'}>
            More
          </Button>
        </Draggable>
        <Drawer onClose={onClose} open={open} width={'90vw'} title={'Danh sách customer, watchlist and cashflow'}>
          <Box mb={5}>
            <Customer data={customer} />
          </Box>
          <Box mb={5}>
            <WatchList data={watchList} />
          </Box>
          <Box mb={5}>
            <CashFlow data={cashFlow} />
          </Box>
        </Drawer>
        <Import />
      </Container>
    </Layout >
  );
};

export default DashboardFeature;
