import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useProvideAuth } from '../hooks';
import { AuthContext } from '../contexts';
import LoginFeature from '../features/Login/index';
import DashboardFeature from '../features/Dashboard/index';
import CustomerFeature from '../features/Customer/index';
import ProtectedRoute from './ProtectedRoute';

export default function Routes() {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      <Router>
        <Switch>
          {/* Place more specific routes before less specific ones */}
          <ProtectedRoute path="/login" component={LoginFeature} reverse={true} />
          <ProtectedRoute path="/customer/:customerId" component={CustomerFeature} />
          <ProtectedRoute path="/" component={DashboardFeature} exact />
          {/* Add a generic 404 route if needed */}
          {/* <Route component={NotFound} path="*" /> */}
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
