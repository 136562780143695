import { useState, useEffect } from 'react';
import useProvideAuth from '../useProvideAuth';
import useFetchData from './useFetchData';

export default function useStockPortfolio(params) {
  const { isLogin } = useProvideAuth();
  const [stockPortfolio, setStockPortfolio] = useState(null);

  const { data, error, mutate } = useFetchData('stock-portoflio', params);

  useEffect(() => {
    if (data && isLogin) {
      setStockPortfolio(data[0]);
    }
    if (error) {
      setStockPortfolio(null);
    }
  }, [data, error, isLogin]);

  return { stockPortfolio, mutate };
}