import { useEffect, useState } from 'react';

export default function useProvideAuth() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLogin, setIsLogin] = useState(!!JSON.parse(localStorage.getItem('isLogin')));

  const login = token => {
    localStorage.setItem('isLogin', true);
    localStorage.setItem('token', token);
    setToken(token);
    setIsLogin(true);
    window.location.reload();
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    setIsLogin(false);
    window.location.reload();
  };

  useEffect(() => {
    // Sync all tabs on login or logout
    window.addEventListener('storage', e => {
      if (e.key === 'isLogin') {
        setIsLogin(e.newValue);
      }
    });
  });

  return { token, login, logout, isLogin };
}
