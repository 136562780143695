// CustomerFeature.tsx
import React, { useState } from 'react';
import Layout from '../../layouts/Customer';
import { Drawer } from 'antd';
import { Box, Container, SimpleGrid, Button } from '@chakra-ui/react'
import Draggable from 'react-draggable';
import { useParams } from "react-router-dom";
import CustomerStats from '../Dashboard/components/CustomerStats';
import Overview from './components/Overview';
import CashFLow from './components/CashFlow';
import PortfolioSummary from './components/PortfolioSummary';
import AssetAllocation from './components/AssetAllocation';
import Transaction from './components/Transaction';
import StockPorfolio from './components/StockPorfolio';
import PortfolioStats from './components/PortfolioStats';
import { usePortfolioStats, useAssetAllocation, useAssetStatus, usePortfolioSummary, useStockPortfolio, useRevenue, useCustomerProgress } from '../../hooks/customer';

interface RouteParams {
  customerId: string;
}

const CustomerFeature: React.FC = () => {
  const { customerId }: RouteParams = useParams<RouteParams>();

  const { portfolioStats } = usePortfolioStats({ account_id: customerId });
  const { assetAllocation } = useAssetAllocation({ account_id: customerId });
  const { assetStatus } = useAssetStatus({ account_id: customerId });
  const { portfolioSummary } = usePortfolioSummary({ account_id: customerId, year: 2024 });
  const { stockPortfolio } = useStockPortfolio({ account_id: customerId });
  const { revenue } = useRevenue({ account_id: customerId });
  const { customerProgress } = useCustomerProgress({ account_id: customerId });

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <Container maxW={'container.6xl'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box>
            <Overview data={customerProgress} />
            <Box boxShadow={'base'} rounded={'md'} p={2} mt={10}>
              <CashFLow data={revenue} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mt={10}>
              <PortfolioSummary portfolioSummary={portfolioSummary} />
            </Box>
          </Box>
          <Box>
            <PortfolioStats data={portfolioStats} />
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <Transaction assetStatus={assetStatus} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <AssetAllocation assetAllocation={assetAllocation} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mb={10}>
              <StockPorfolio stockPortfolio={stockPortfolio} />
            </Box>
          </Box>
        </SimpleGrid>
        <Draggable>
          <Button
            className={'import'}
            onClick={showDrawer}
            as={'button'}
            position={'fixed'}
            bottom={'60px'}
            right={'0px'}>
            More
          </Button>
        </Draggable>
      </Container>
      <Drawer onClose={onClose} open={open} width={'90vw'} title={'Danh sách khách hàng'}>
        <Box mb={5}>
          <CustomerStats scrollX='max-content'  />
        </Box>
      </Drawer>
    </Layout>
  );
};

export default CustomerFeature;
