import { useState, useEffect } from 'react';
import useProvideAuth from '../useProvideAuth';
import useFetchData from './useFetchData';

export default function usePortfolioStats(params) {
  const { isLogin } = useProvideAuth();
  const [portfolioStats, setPortfolioStats] = useState(null);

  const { data, error, mutate } = useFetchData('portfolio-stats', params);

  useEffect(() => {
    if (data && isLogin) {
      setPortfolioStats(data[0]);
    }
    if (error) {
      setPortfolioStats(null);
    }
  }, [data, error, isLogin]);

  return { portfolioStats, mutate };
}