import React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  GridItem,
  Slider,
  SliderMark,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  Text
} from '@chakra-ui/react';
import { Col, Row, Empty } from 'antd';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CNumberToMillions from '../../../../components/CNumberToMillions';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import './styles.scss';

const labelTopStyles = {
  mt: '-8',
  ml: '-2.5',
  fontSize: 'sm',
};
const labelBottomStyles = {
  mt: '2',
  ml: '-5',
  fontSize: 'sm',
};

interface ComponentProps {
  data: any | null;
}

interface CfHist {
  date: string;
  amount: number;
}

function calculatePercentage(custStartDateStr: string, custEndDateStr: string, curDateStr: string): number {
  const custStartDate = new Date(custStartDateStr);
  const custEndDate = new Date(custEndDateStr);
  const curDate = new Date(curDateStr);

  const totalMilliseconds = custEndDate.getTime() - custStartDate.getTime();
  const elapsedMilliseconds = curDate.getTime() - custStartDate.getTime();

  const percentage = (elapsedMilliseconds / totalMilliseconds) * 100;

  return parseFloat(percentage.toFixed(0));
}

function calculatePosition(dateStr: string, custStartDateStr: string, custEndDateStr: string): number {
  const date = new Date(dateStr);
  const custStartDate = new Date(custStartDateStr);
  const custEndDate = new Date(custEndDateStr);

  const totalMilliseconds = custEndDate.getTime() - custStartDate.getTime();
  const elapsedMilliseconds = date.getTime() - custStartDate.getTime();

  const position = (elapsedMilliseconds / totalMilliseconds) * 100;

  return parseFloat(position.toFixed(0));
}

const Overview: React.FC<ComponentProps> = ({ data }) => {

  if (!data) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  const percentage = calculatePercentage(data.cust_start_date, data.cust_end_date, data.cur_date);
  const cf_hist = [data?.cf_hist[data.cf_hist?.length - 1]];

  return (
    <Row align="middle" justify="center">
      <Col xs={24} xl={14}>
        <GridItem paddingRight={4} paddingY={2} border={'1px'} borderColor={'brand.500'} borderRadius={'lg'} boxShadow='base'>
          <Box paddingX={4} paddingY={8}>
            <Flex minWidth={'max-content'} alignItems={'center'} gap={2}>
              <Text flex={20} minWidth={95}>Thời gian quản lý</Text>
              <Slider flex={80} defaultValue={percentage} isReadOnly>
                <SliderMark value={0} {...labelTopStyles}>
                  <Moment format="DD/MM">{data.cust_start_date}</Moment>
                </SliderMark>
                {cf_hist?.map((cell: CfHist, cellIndex: number) => (
                  <SliderMark className={'below-text'} value={calculatePosition(cell.date, data.cust_start_date, data.cust_end_date)} {...labelTopStyles}>
                    <Moment format="DD/MM">{cell.date}</Moment>
                  </SliderMark>
                ))}
                <SliderMark value={100} {...labelTopStyles}>
                  <Moment format="DD/MM">{data.cust_end_date}</Moment>
                </SliderMark>
                <SliderMark
                  value={percentage}
                  textAlign={'center'}
                  mt={'-10'}
                  ml={'-5'}
                >
                  {percentage}%
                </SliderMark>
                <SliderTrack bg={'#D9D9D9'} height={'15px'} borderRadius={'5px'}>
                  <SliderFilledTrack bg={'brand.500'} />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Flex>
          </Box>
          <Box paddingX={4} paddingY={8}>
            <Flex minWidth={'max-content'} alignItems={'center'} gap={2}>
              <Text flex={20} minWidth={'95px'}>Vốn đầu tư</Text>
              <Slider flex={80} defaultValue={percentage} isReadOnly>
                <SliderMark value={0} {...labelBottomStyles}>
                  <CNumberToMillions number={data.capital} />
                </SliderMark>
                {cf_hist?.map((cell: CfHist, cellIndex: number) => (
                  <SliderMark className={'above-text'} value={calculatePosition(cell.date, data.cust_start_date, data.cust_end_date)} {...labelTopStyles}>
                    <CNumberToMillions number={cell.amount} />
                  </SliderMark>
                ))}
                <SliderMark value={100} {...labelBottomStyles}>
                  <CNumberToMillions number={data.cur_nav} />
                </SliderMark>
                <SliderTrack bg={'brand.500'} height={'15px'} borderRadius={'5px'}>
                  <SliderFilledTrack bg={'#D9D9D9'} />
                </SliderTrack>
              </Slider>
            </Flex>
          </Box>
        </GridItem>
      </Col>
      <Col xs={24} xl={10}>
        <GridItem colStart={{ base: 1, md: 4 }} colEnd={{ base: 1, md: 6 }} paddingX={2} paddingY={2}>
          <Flex height={'100%'} alignItems={'center'} justify={'space-between'}>
            <Box textAlign={'center'}>
              <CircularProgress value={100} color={'brand.500'} size={'95px'} thickness={'1px'}>
                <CircularProgressLabel>
                  <NumericFormat value={parseFloat(data.benchmark).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </CircularProgressLabel>
              </CircularProgress>
              <Text>Benchmark</Text>
            </Box>
            <Box className={'chakra-progress-gray'} textAlign={'center'}>
              <CircularProgress value={100} color={'brand.500'} size={'95px'} thickness={'1px'}>
                <CircularProgressLabel>
                  <CNumberToPercent number={data.hurdle_rate} />
                </CircularProgressLabel>
              </CircularProgress>
              <Text>Hurdle rate</Text>
            </Box>
            <Box className={'chakra-progress-gray'} textAlign={'center'}>
              <CircularProgress value={100} color={'brand.500'} size={'95px'} thickness={'1px'} sx={{ fill: 'brand.500' }}>
                <CircularProgressLabel>
                  <CNumberToPercent number={data.perf_fee_rate} />
                </CircularProgressLabel>
              </CircularProgress>
              <Text>Perf. fee rate</Text>
            </Box>
          </Flex>
        </GridItem>
      </Col>
    </Row>
  );
};

export default Overview;
