import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';

export default function usePostFile(url, params) {
  const { token, isLogin } = useProvideAuth();
  const [dataFile, setDataFile] = useState(null);
  const [errorFile, setErrorFile] = useState(null);

  const postFile = async (url, file) => {
    if (url) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/v1/${url}`, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
          },
          body: formData
        });

        const responseData = await response.json();

        if (response.ok) {
          setDataFile(responseData?.data);
        } else {
          setErrorFile(responseData?.detail);
        }
      } catch (error) {
        setErrorFile(error.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      postFile(url, params);
    }
  }, [isLogin, token, url, params]);

  return { dataFile, errorFile, mutate: postFile };
}