// Footer.tsx
import React from 'react';
import { Box, Container, Text } from '@chakra-ui/react';

const Footer: React.FC = () => {
  return (
    <Box bg="brand.500" p={4} color="white" mt={8}>
      <Container justifyContent="space-between" maxW="container.6xl" mx="auto">
        <Text>FINSUCCESS - Copyright 2024. All rights reserved.</Text>
      </Container>
    </Box>
  );
};

export default Footer;
