import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function usePortfolioDetails(params) {
  const { isLogin } = useProvideAuth();
  const [portfolioDetails, setPortfolioDetails] = useState(null);

  // Reuse the useFetchData hook
  const { data, error, mutate } = useFetchData(`portfolio/details`, params);

  useEffect(() => {
    if (data && isLogin) {
      setPortfolioDetails(data);
    }
    if (error) {
      setPortfolioDetails(null);
    }
  }, [data, error, isLogin]);

  return { portfolioDetails, mutate };
}