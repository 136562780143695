import * as React from "react"
import { Link } from "react-router-dom";
import {
  chakra,
  ImageProps,
  forwardRef
} from "@chakra-ui/react"
import logo from "./logo.webp"

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  return (
    <Link to="/">
      <chakra.img maxWidth={150} src={logo} ref={ref} {...props} />
    </Link>
  );
});
