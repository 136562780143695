import React from 'react';
import { Col, Row, Space } from 'antd';
import { Flex, Text, Heading, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Slider, Box, chakra } from '@chakra-ui/react';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../CNumberToPercent';
import CNumberToMillions from '../CNumberToMillions';
import './styles.scss';

interface DataProps {
    data: any;
}

const CStockAlloc: React.FC<DataProps> = ({ data }) => {

    const labelStyles = {
        mt: '3',
        ml: '-6',
        fontSize: 'sm',
    }

    return (
        <Box className={'c-ant-stock-alloc'}>
            <Row style={{ marginBottom: '25px', alignItems: 'top' }}>
                <Col span={3} style={{ textAlign: 'left' }}>
                    <Heading as='h4' size='md' color={'brand.500'}>
                        {data?.symbol}
                    </Heading>
                </Col>
                <Col span={15} style={{ textAlign: 'left' }}>
                    <Flex>
                        <Text w='150px'>Cost price:</Text>
                        <Slider className='c-ant-slider' isReadOnly>
                            <SliderMark value={0} {...labelStyles}>
                                <NumericFormat value={parseFloat(data?.min_cost_price).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                            </SliderMark>
                            <SliderMark value={100} {...labelStyles}>
                                <NumericFormat value={parseFloat(data?.max_cost_price).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                            </SliderMark>
                            <SliderMark
                                value={50}
                                textAlign='center'
                                mt='-6'
                                ml='-10'
                            >
                                Avg: <NumericFormat value={parseFloat(data?.avg_cost_price).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                            </SliderMark>
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </Flex>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Space>
                        <Text textTransform={'capitalize'}>{data?.trend}:</Text><CNumberToPercent number={data?.pct_trend_value} color />
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginBottom: '5px', alignItems: 'bottom' }}>
                <Col span={3}></Col>
                <Col span={10} style={{ textAlign: 'left' }}>
                    <Space>
                        <chakra.img className={'calendar'} src={process.env.PUBLIC_URL + '/resources/images/user-down.svg'} />
                        <Space>
                            <Text fontWeight={'bold'} color={'#DA1818'}>{data?.loss_custs_cnt} |</Text>
                            <Space>
                                <Text><CNumberToMillions number={data?.loss_m_value} /></Text>
                                <Text><CNumberToPercent number={data?.loss_pct_PL} color /></Text>
                            </Space>
                        </Space>
                    </Space>
                </Col>
                <Col span={7} style={{ textAlign: 'left' }}>
                    Market price: <NumericFormat value={data?.m_price} displayType={'text'} thousandSeparator={true} />
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                    <Space>
                        <chakra.img className={'calendar'} src={process.env.PUBLIC_URL + '/resources/images/down-arrow.svg'} />
                        <Text><NumericFormat value={parseFloat(data?.short).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginBottom: '5px', alignItems: 'bottom' }}>
                <Col span={3}></Col>
                <Col span={10} style={{ textAlign: 'left' }}>
                    <Space>
                        <chakra.img className={'calendar'} src={process.env.PUBLIC_URL + '/resources/images/user-up.svg'} />
                        <Space>
                            <Text fontWeight={'bold'} color={'#1AC917'}>{data?.profit_custs_cnt} |</Text>
                            <Space>
                                <Text><CNumberToMillions number={data?.profit_m_value} /></Text>
                                <Text><CNumberToPercent number={data?.profit_pct_PL} color /></Text>
                            </Space>
                        </Space>
                    </Space>
                </Col>
                <Col span={7} style={{ textAlign: 'left' }}>
                    Target price: <NumericFormat value={data?.t_price} displayType={'text'} thousandSeparator={true} />
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                    <Space>
                        <chakra.img className={'calendar'} src={process.env.PUBLIC_URL + '/resources/images/up-arrow.svg'} />
                        <Text><NumericFormat value={parseFloat(data?.call).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                    </Space>
                </Col>
            </Row>
        </Box>
    );
}

export default CStockAlloc;