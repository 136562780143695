import React from 'react';
import { Center } from '@chakra-ui/react';
import { Spin } from 'antd';
import './styles.scss';

function CLoading() {
    return (
        <Center>
            <Spin tip="Loading..." size="large" />
        </Center>
    );
}

export default CLoading;