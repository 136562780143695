import React from 'react';
import { Col, Row } from 'antd';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../CNumberToPercent';
import './styles.scss';

interface TooltipProps {
  data: any;
}

const CChartTooltip: React.FC<TooltipProps> = ({ data }) => {

  return (
    <div className="MyChartTooltip">
      <Row style={{ alignItems: 'top' }}>
        <Col span={12} style={{ textAlign: 'right' }}>Giá vốn: <NumericFormat value={data?.avg_cost_price} displayType={'text'} thousandSeparator={true} /></Col>
        <Col span={12} style={{ textAlign: 'right' }}>Giá mục tiêu: <NumericFormat value={data?.t_price} displayType={'text'} thousandSeparator={true} /></Col>
      </Row>
      <Row style={{ paddingTop: '5px', alignItems: 'top' }}>
        <Col span={12} style={{ textAlign: 'right' }}>Giá thị trường: <NumericFormat value={data?.m_price} displayType={'text'} thousandSeparator={true} /></Col>
        <Col span={12} style={{ textAlign: 'right', textTransform: 'capitalize' }}>{data?.trend}: <CNumberToPercent number={data?.pct_trend_value} color /></Col>
      </Row>
      <Row style={{ paddingTop: '15px', alignItems: 'top', textAlign: 'center' }}>
        <Col span={24}>
          Tỷ trọng mục tiêu: N/A
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px', alignItems: 'top', textAlign: 'center' }}>
        <Col span={24}>
          KL mục tiêu: N/A
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px', alignItems: 'top', textAlign: 'center' }}>
        <Col span={24}>
          KL cần mua thêm/bán ra: <NumericFormat value={data?.call > 0 ? data?.call : data?.short} displayType={'text'} thousandSeparator={true} />
        </Col>
      </Row>
    </div>
  );
};

export default CChartTooltip;
