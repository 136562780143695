import { useState, useEffect } from 'react';
import useProvideAuth from '../useProvideAuth';
import useFetchData from './useFetchData';

export default function usePortfolioSummary(params) {
  const { isLogin } = useProvideAuth();
  const [portfolioSummary, setPortfolioSummary] = useState(null);

  const { data, error, mutate } = useFetchData('portfolio-summary', params);

  useEffect(() => {
    if (data && isLogin) {
      setPortfolioSummary(data[0]);
    }
    if (error) {
      setPortfolioSummary(null);
    }
  }, [data, error, isLogin]);

  return { portfolioSummary, mutate };
}