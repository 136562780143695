// StockPorfolio.tsx
import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Grid, GridItem, Text, Box, chakra } from '@chakra-ui/react';
import { Row, Col, Empty, Typography, Space, Input, Button, Modal, Table } from 'antd';
import type { TableProps } from 'antd';
import CChartTooltip from '../../../../components/CChartTooltip';
import Highcharts, { TooltipFormatterContextObject } from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";
import PieChart from "highcharts-react-official";
import VariablePie from "highcharts/modules/variable-pie.js";
import './styles.scss'

drilldow(Highcharts);
VariablePie(Highcharts);

const { Title } = Typography;

interface ComponentProps {
  stockPortfolio: any | null;
}

interface StockPorfolio {
  symbol: string;
  m_price: number;
  weight: number;
}

interface DataType {
  key: string;
  symbol: string;
  volumn: number;
  m_price: number;
  weight: number;
  price: number;
  value: number;
  type: string;
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Mã CK',
    dataIndex: 'symbol',
    key: 'symbol',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Số lượng',
    dataIndex: 'volumn',
    key: 'volumn',
  },
  {
    title: 'Giá thị trường',
    dataIndex: 'm_price',
    key: 'm_price',
  },
  {
    title: 'Tỷ trọng',
    key: 'weight',
    dataIndex: 'weight',
  },
  {
    title: 'Giá',
    key: 'price',
    dataIndex: 'price',
  },
  {
    title: 'Giá trị',
    key: 'value',
    dataIndex: 'value',
  },
  {
    title: 'Loại',
    key: 'type',
    dataIndex: 'type',
  }
];

const data: DataType[] = [];

const convertToPercentage = (decimal: number): number => {
  // Multiply the decimal value by 100 to get the percentage
  const percentage = decimal * 100;
  // Round the percentage to one decimal place
  return Math.round(percentage * 10) / 10;
};

const convertToMillions = (inputNumber: string) => {
  const number = parseFloat(inputNumber);
  if (!isNaN(number)) {
    const millions = number / 1000000;
    return `${parseFloat(millions?.toFixed(1))} M`;
  } else {
    return 'N/A';
  }
};

const renderTooltip = (point: any) => {
  return ReactDOMServer.renderToString(
    <CChartTooltip
      data={point}
    />
  );
};

const StockPorfolio: React.FC<ComponentProps> = ({ stockPortfolio }) => {

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  if (!(stockPortfolio && stockPortfolio['total_value'] && stockPortfolio['stock_alloc'])) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const totalValue = convertToMillions(stockPortfolio['total_value']);
  const nf = new Intl.NumberFormat();
  const stockAlloc = (stockPortfolio['stock_alloc'] as StockPorfolio[])?.map(item => ({
    ...item,
    name: item.symbol,
    m_price: nf.format(item?.m_price),
    y: convertToPercentage(item?.weight),
  }));

  // Create the chart
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [[0, '#EDF2F7']],
      },
    },
    colors: ['#BFD0EA', '#90B3E5', '#6F98D2', '#3A65A3', '#1F4172'],
    title: {
      text: totalValue,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        shadow: false,
        center: ['50%', '50%'],
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 4,
        dataLabels: {
          pointFormat:
            "<div class='pie-box'>" +
            "<b class='pie-label'>{point.name}</b><br/>" +
            "<p class='pie-value'>({point.m_price}, {point.y}%)</p>" +
            "</div>",
          style: {
            color: '#1F4172',
            textOutline: 'none',
          },
          useHTML: true
        },
        point: {
          events: {},
        },
      },
    },
    tooltip: {
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return renderTooltip(this.point);
      },
    },
    series: [
      {
        name: 'Total',
        colorByPoint: true,
        innerSize: '50%',
        data: stockAlloc
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [{}, { id: 'versions', dataLabels: { enabled: false } }],
          },
        },
      ],
    },
  };

  return (
    <>
      <Box>
        <Grid position={'relative'}>
          <Text align={'center'} fontSize={'2xl'}>TỶ TRỌNG CỔ PHIẾU TRONG DANH MỤC</Text>
          <GridItem>
            <Box>
              <PieChart highcharts={Highcharts} options={options} />
            </Box>
          </GridItem>
          <Button type="link" onClick={showModal} style={{ position: 'absolute', top: 0, right: 0, padding: 0 }}>
            <chakra.img className='calculator' src={process.env.PUBLIC_URL + '/resources/images/calculator.svg'} />
          </Button>
          <Modal
            width={1000}
            open={open}
            closable={false}
            footer={[
              <Button key="cancel" type="default" onClick={handleCancel}>
                Hủy
              </Button>,
              <Button key="update" type="primary" loading={loading} onClick={handleOk}>
                Phân bổ
              </Button>,
            ]}
          >
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>
                <Title level={5}>Ms THÀNH - 085C008265</Title>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Space>
                  <Text>Số dư tiền mặt:</Text>
                  <Input value={'120,000,000'} disabled />
                </Space>
              </Col>
            </Row>
            <Box mt={10}>
              <Table columns={columns} dataSource={data} />
            </Box>
          </Modal>
        </Grid>
      </Box>
    </>
  );
};

export default StockPorfolio;
