// Summary.tsx
import React from 'react';
import { Grid, GridItem, Box, Heading, Text } from '@chakra-ui/react';
import CLoading from '../../../../components/CLoading';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CNumberToMillions from '../../../../components/CNumberToMillions';
import { usePortfolioStats } from '../../../../hooks';
import './styles.scss'

const Summary: React.FC = () => {

  const { portfolioStats } = usePortfolioStats();

  if (!portfolioStats) return <CLoading />;

  return (
    <Grid templateColumns={'repeat(5, 1fr)'} boxShadow={'md'}>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>{portfolioStats['no_custs']}</Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Số lượng khách hàng</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={portfolioStats['nav']} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>NAV</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={portfolioStats['capital']} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Vốn ban đầu</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']} borderRight={'1px'} borderColor={'brand.200'}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToPercent number={portfolioStats['avg_ror']} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>TSSL trung bình</Text>
        </Box>
      </GridItem>
      <GridItem py={5} w={'100%'} bg={'brand.500'} color={'#ffffff'}>
        <Box textAlign={['center']}>
          <Heading size={{ base: 'md', lg: 'xl' }}>
            <CNumberToMillions number={portfolioStats['perf_fee']} />
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }}>Phí hiệu quả</Text>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default Summary;
