import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function useStockPortfolio(params) {
  const { isLogin } = useProvideAuth();
  const [stockPortfolio, setStockPortfolio] = useState(null);

  // Reuse the useFetchData hook
  const { data, error, mutate } = useFetchData(`portfolio/stock-portfolio`, params);

  useEffect(() => {
    if (data && isLogin) {
      setStockPortfolio(data[0]);
    }
    if (error) {
      setStockPortfolio(null);
    }
  }, [data, error, isLogin]);

  return { stockPortfolio, mutate };
}