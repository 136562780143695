import { useEffect, useState } from 'react';
import useSWR from 'swr';
import useProvideAuth from '../useProvideAuth';

export default function useFetchData(url, params) {
  const provideAuth = useProvideAuth();
  const { token, isLogin } = useProvideAuth();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // Fetch data function
  const fetchData = async (url, token, params) => {
    const res = await fetch(`${process.env.REACT_APP_BACKEND}/v1/portfolio/customer/${url}/?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      const error = new Error('An error occurred while fetching the data.');
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }

    return res.json();
  };

  // SWR hook for data fetching
  const { data: responseData, error: responseError, mutate } = useSWR(
    [url, token, JSON.stringify(params)],
    () => fetchData(url, token, params)
  );

  // Update state based on response
  useEffect(() => {
    if (responseData?.status === 'success' && isLogin) {
      setData(responseData?.data);
    }
    if (responseError) {
      setError(responseError);
      if (responseError.status === 401) {
        provideAuth.logout();
      }
    }
  }, [responseData, responseError]);

  return { data, error, mutate };
}
