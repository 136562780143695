// Header.tsx
import React, { useState } from 'react';
import { Box, Container, Heading, Grid, Flex, Text, chakra } from '@chakra-ui/react';
import { Logo } from '../Logo';
import { DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import moment from "moment";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './styles.scss';

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY/MM/DD';

interface ComponentProps {
    customer: any | null
}
const MHeader: React.FC<ComponentProps> = ({ customer = null }) => {

    const [date, setDate] = useState(localStorage.getItem('currentDate') || moment().format(dateFormat).toString());
    const isLogin = localStorage.getItem('isLogin') || false;

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDate(date.format(dateFormat).toString());
        localStorage.setItem('currentDate', date.format(dateFormat).toString());
    };

    return (
        <Box className='header' bg="brand.500" p={2} boxShadow='base'>
            <Container justifyContent="space-between" maxW="container.6xl" mx="auto">
                <Grid templateColumns='repeat(2, 1fr)' gap={6} alignItems={'center'}>
                    <Box>
                        <Logo />
                    </Box>
                    <Box className='right'>
                        {customer && customer[0] && (
                            <Heading fontSize="xs" color={'white'}>{`${customer[0].name} - ${customer[0].account_id}`}</Heading>
                        )}
                        {isLogin && (
                            <Box className='right'>
                                <Flex justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                                    <Text fontSize={{ base: 'xs', lg: 'sm' }} color={'white'}>
                                        Dữ liệu cập nhật đến ngày
                                        <DatePicker
                                            className='c-ant-picker'
                                            allowClear={false}
                                            suffixIcon={
                                                <chakra.img className='calendar' src={process.env.PUBLIC_URL + '/resources/images/calendar.svg'} />
                                            }
                                            value={dayjs(date, dateFormat)}
                                            maxDate={dayjs()}
                                            format={dateFormat}
                                            onChange={onChange}
                                            inputReadOnly={true}
                                        />
                                    </Text>
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default MHeader;
