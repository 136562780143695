// StockPorfolio.tsx
import React from 'react';
import { Col, Row } from 'antd';
import { Text, Box } from '@chakra-ui/react';
import CLoading from '../../../../components/CLoading';
import CStockAlloc from '../../../../components/CStockAlloc';
import { useStockPortfolio } from '../../../../hooks';
import Highcharts from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";
import PieChart from "highcharts-react-official";
import VariablePie from "highcharts/modules/variable-pie.js";
import './styles.scss'

drilldow(Highcharts);
VariablePie(Highcharts);

interface StockPorfolio {
  symbol: string;
  m_price: number;
  weight: number;
}

const convertToPercentage = (decimal: number): number => {
  // Multiply the decimal value by 100 to get the percentage
  const percentage = decimal * 100;
  // Round the percentage to one decimal place
  return Math.round(percentage * 10) / 10;
};

const convertToMillions = (inputNumber: string) => {
  const number = parseFloat(inputNumber);
  if (!isNaN(number)) {
    const millions = number / 1000000;
    return `${parseFloat(millions?.toFixed(1))} M`;
  } else {
    return 'N/A';
  }
};

const StockPorfolio: React.FC = () => {

  const { stockPortfolio } = useStockPortfolio();
  if (!(stockPortfolio && stockPortfolio['total_value'] && stockPortfolio['stock_alloc'])) return <CLoading />;

  const totalValue = convertToMillions(stockPortfolio['total_value']);
  const nf = new Intl.NumberFormat();
  const stockAlloc = (stockPortfolio['stock_alloc'] as StockPorfolio[])?.map(item => ({
    ...item,
    name: item.symbol,
    m_price: nf.format(item?.m_price),
    y: convertToPercentage(item?.weight),
  }));

  // Create the chart
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [[0, '#EDF2F7']],
      },
    },
    colors: ['#BFD0EA', '#90B3E5', '#6F98D2', '#3A65A3', '#1F4172'],
    title: {
      text: totalValue,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        shadow: false,
        center: ['50%', '50%'],
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 4,
        size: '100%',
        dataLabels: {
          pointFormat:
            "<div class='pie-box'>" +
            "<b class='pie-label'>{point.name}</b><br/>" +
            "<p class='pie-value'>({point.m_price}, {point.y}%)</p>" +
            "</div>",
          style: {
            color: '#1F4172',
            textOutline: 'none',
          },
          useHTML: true
        },
        point: {
          events: {},
        },
      },
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        name: 'Total',
        colorByPoint: true,
        innerSize: '50%',
        data: stockAlloc
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [{}, { id: 'versions', dataLabels: { enabled: false } }],
          },
        },
      ],
    },
  };

  return (
    <>
      <Text align={'center'} fontSize={'2xl'}>TỶ TRỌNG CỔ PHIẾU TRONG DANH MỤC</Text>
      <Row justify="space-around" align="middle" style={{ padding: '30px 0' }}>
        <Col span={14}>
          <Box>
            <PieChart highcharts={Highcharts} options={options} className={'c-chart'} />
          </Box>
        </Col>
        <Col span={10}>
          <Box className={'c-stock-alloc'} maxH={'500px'} overflowY={'scroll'}>
            {stockAlloc?.map((item, index) => (
              <CStockAlloc key={index} data={item} />
            ))}
          </Box>
        </Col>
      </Row>
    </>
  );
};

export default StockPorfolio;
