import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function useAssetAllocation(params) {
  const { isLogin } = useProvideAuth();
  const [assetAllocation, setAssetAllocation] = useState(null);

  // Reuse the useFetchData hook
  const { data, error, mutate } = useFetchData(`portfolio/asset-allocation`, params);

  useEffect(() => {
    if (data && isLogin) {
      setAssetAllocation(data[0]);
    }
    if (error) {
      setAssetAllocation(null);
    }
  }, [data, error, isLogin]);

  return { assetAllocation, mutate };
}