import React from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Card,
  CardBody
} from '@chakra-ui/react';
import { NumericFormat } from 'react-number-format';

interface CTablePreviewProps {
  tLabels?: string;
  theadData?: any[] | null;
  tbodyData?: any[] | null;
  tfootData?: any[] | null;
  tableKey?: Record<string, any>;
}

const CTablePreview: React.FC<CTablePreviewProps> = ({ tLabels = '', theadData = [], tbodyData = [], tfootData = [], tableKey = {} }) => {

  return (
    <Card border={'1px'} boxShadow={'base'} minHeight={'150px'} maxHeight={'300px'} overflowY={'scroll'}>
      <CardBody>
        <TableContainer>
          <Table size={'sm'}>
            <Thead>
              <Tr>
                {theadData && theadData.map((item, index) => (
                  <Th key={index} borderBottom={'2px'} color={'black'} isNumeric={index > 0}>
                    <Text align={'center'}>{item}</Text>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {tbodyData && tbodyData.map((row, rowIndex) => (
                <Tr key={rowIndex} cursor={'pointer'} backgroundColor={row?.is_dup_infile || row?.is_dup_indb ? 'lightyellow' : ''}>
                  {Object.keys(row).map((key, index) => (
                    tableKey[index] && (
                      <Td key={key} borderBottom={'1px'} borderColor={'#EC9E56'}>
                        {typeof row[tableKey[index]] === 'number' ? (
                          <Text align={'right'}><NumericFormat value={parseFloat(row[tableKey[index]]).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        ) : (
                          <Text align={'center'}>{row[tableKey[index]] ?? '-'}</Text>
                        )}
                      </Td>
                    )
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default CTablePreview;
