// PortfolioSummary.tsx
import React from 'react';
import { Box, Text, TableContainer, Table, TableCaption, Thead, Tr, Th, Heading, Tbody, Td, Divider } from '@chakra-ui/react';
import { Empty } from 'antd';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import moment from "moment";
import './styles.scss'

const styles = {
  borderStyle: 'dashed !important',
  border: '1px',
  borderColor: 'brand.500',
  boxShadow: 'base',
  rounded: 'md',
  p: 2
};

interface ComponentProps {
  portfolioSummary: any | null;
}

const PortfolioSummary: React.FC<ComponentProps> = ({ portfolioSummary }) => {

  if (!portfolioSummary) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const currentDate = new Date(localStorage.getItem('currentDate')?.toString() || moment().format('YYYY/MM/DD').toString());
  
  return (
    <Box {...styles}>
      <Text fontSize={'3xl'} color={'#000000'} textAlign={'center'} paddingBottom={50}>TỔNG KẾT DANH MỤC ĐẦU TƯ NĂM {currentDate?.getFullYear()}</Text>
      <TableContainer>
        <Table size={'sm'}>
          <TableCaption paddingTop={100}><Text fontSize={'xl'} fontStyle={'italic'} color={'#000000'}>FinSuccess hân hạnh được đồng hành!</Text></TableCaption>
          <Thead>
            <Tr>
              <Th></Th>
              <Th isNumeric>
                <Heading as={'h4'} size={'md'} color={'#000000'} textTransform={'initial'}>Giá trị</Heading>
              </Th>
            </Tr>
            <Tr>
              <Th paddingRight={0}><Divider orientation={'horizontal'} borderColor={'brand.500'} /></Th>
              <Th paddingLeft={0}><Divider orientation={'horizontal'} borderColor={'brand.500'} /></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td><Heading as={'h4'} size={'md'}>TÀI SẢN RÒNG HIỆN TẠI:</Heading></Td>
              <Td isNumeric>
                <Heading as={'h4'} size={'md'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.nav).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td paddingLeft={10}><Text fontSize={'md'}>Tổng vốn đầu tư</Text></Td>
              <Td isNumeric>
                <Text fontSize={'md'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.capital).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Text>
                <Divider orientation={'horizontal'} paddingTop={3} borderColor={'brand.500'} />
              </Td>
            </Tr>
            <Tr>
              <Td><Heading as={'h4'} size={'md'}>LỢI NHUẬN:</Heading></Td>
              <Td isNumeric>
                <Heading as={'h4'} size={'md'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.revenue).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td><Heading as={'h4'} size={'md'}>ROR:</Heading></Td>
              <Td isNumeric>
                <Heading as={'h4'} size={'md'} color={'#1AC917'}>
                  <CNumberToPercent number={portfolioSummary?.ror} color={true} />
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td paddingLeft={10}><Text fontSize={'md'}>Lợi nhuận cơ sở</Text></Td>
              <Td isNumeric>
                <Text fontSize={'md'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.required_revenue).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td paddingLeft={10}><Text fontSize={'md'}>Lỗ lũy kế kỳ trước</Text></Td>
              <Td isNumeric>
                <Text fontSize={'md'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.prev_acc_loss).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Text>
                <Divider orientation={'horizontal'} paddingTop={3} borderColor={'brand.500'} />
              </Td>
            </Tr>
            <Tr>
              <Td><Heading as={'h4'} size={'md'}>LỢI NHUẬN VƯỢT:</Heading></Td>
              <Td isNumeric>
                <Heading as={'h4'} size={'md'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.exceed_revenue).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td><Heading as={'h4'} size={'md'} color={'#FF7A00'}>PHÍ HIỆU QUẢ:</Heading></Td>
              <Td isNumeric>
                <Heading as={'h4'} size={'md'} color={'#FF7A00'}>
                  <NumericFormat value={parseFloat(portfolioSummary?.perf_fee).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                </Heading>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PortfolioSummary;
