import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function useCustomer(params) {
  const { isLogin } = useProvideAuth();
  const [customer, setCustomer] = useState(null);

  // Reuse the useFetchData hook
  const { data, error, mutate } = useFetchData(`customer`, params);

  useEffect(() => {
    if (data && isLogin) {
      setCustomer(data);
    }
    if (error) {
      setCustomer(null);
    }
  }, [data, error, isLogin]);

  return { customer, mutate };
}