import React from 'react';
import { NumericFormat } from 'react-number-format';
import './styles.scss';

interface CNumberToPercentProps {
  number: number;
  color?: boolean;
}

const CNumberToPercent: React.FC<CNumberToPercentProps> = ({ number, color = false }) => {
  
  // Convert number to percentage, round it to the nearest integer, and format it
  const formattedPercentage = Math.round(number * 100);

  let textColor: string;

  if (color) {
    if (formattedPercentage > 0) {
      textColor = 'green';
    } else if (formattedPercentage < 0) {
      textColor = 'red';
    } else {
      textColor = 'orange';
    }
  } else {
    textColor = ''; // Default color if color prop is not true
  }

  return (
    <span style={{ color: textColor }}>
      <NumericFormat suffix={'%'} value={formattedPercentage} displayType={'text'} thousandSeparator={true} />
    </span>
  );
};

export default CNumberToPercent;
