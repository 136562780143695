// LoginFeature.tsx
import React, { useState } from 'react';
import Layout from '../../layouts/Login';
import { FaUserAlt, FaLock } from "react-icons/fa";
import { chakra, Box, Container, Flex, Stack, Button, FormControl, Input, InputGroup, InputLeftElement, InputRightElement, Heading, Avatar } from '@chakra-ui/react';
import { useProvideAuth } from '../../hooks';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const LoginFeature: React.FC = () => {

    const provideAuth = useProvideAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleShowClick = () => setShowPassword(!showPassword);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        const headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Accept", "application/json");

        const params = new URLSearchParams();
        params.append("username", username);
        params.append("password", password);

        // You can perform further actions like API calls or authentication logic
        fetch(`${process.env.REACT_APP_BACKEND}/token`, {
            method: "POST",
            headers: headers,
            body: params,
            redirect: "follow" // Specify type explicitly
        })
            .then(res => res.json())
            .then(json => {
                if (json) {
                    provideAuth.login(json.access_token);
                } else {
                    setError(json.detail);
                }
                setIsLoading(false);
            });
    };

    return (
        <Layout>
            <Container maxW={'container.6xl'}>
                <Flex
                    flexDirection="column"
                    width="100wh"
                    height="calc(100vh - 256px)"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Stack
                        flexDir="column"
                        mb="2"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Avatar bg="brand.500" />
                        <Heading color="brand.400">Welcome</Heading>
                        <Box minW={{ base: "90%", md: "468px" }}>
                            <form onSubmit={handleSubmit}>
                                <Stack
                                    spacing={4}
                                    p="1rem"
                                    backgroundColor="whiteAlpha.900"
                                    boxShadow="md"
                                >
                                    <FormControl>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<CFaUserAlt color="gray.300" />}
                                            />
                                            <Input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents="none"
                                                color="gray.300"
                                                children={<CFaLock color="gray.300" />}
                                            />
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <InputRightElement width="4.5rem">
                                                <Button h="1.75rem" size="sm" onClick={handleShowClick} isLoading={isLoading}>
                                                    {showPassword ? "Hide" : "Show"}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <Button
                                        borderRadius={0}
                                        type="submit"
                                        variant="solid"
                                        colorScheme="brand"
                                        width="full"
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Stack>
                </Flex>
            </Container>
        </Layout>
    );
};

export default LoginFeature;
